import { KyJson } from '@eaphone/network';
import { Auth } from '@eaphone/storage';

export function login({ code, type }) {
  return KyJson.post('third-party/login', {
    auth: false,
    json: { code, type },
  }).then(({ token /* , openId */ } = {}) => {
    if (token) {
      Auth.token = token;
      // Auth.openId = openId;
    } else {
      throw new Error('登录失败');
    }
  });
}
