import { isIOS } from '@eaphone/env';
import { captureException } from '@eaphone/sentry';

const initUrl = document.URL;

export function removeCode(url) {
  const [path, query] = url.split('?');

  const io = new URLSearchParams(query);

  io.delete('code');
  io.delete('state');

  return [path, io.toString()].filter(Boolean).join('?');
}

export function wxConfig(getSignature, { openTagList, jsApiList }) {
  globalThis.controller = new AbortController();

  globalThis.controller.abort();

  const entryUrl = isIOS ? removeCode(initUrl) : document.URL;

  return getSignature(entryUrl, globalThis.controller.signal)
    .then((data) => {
      wx.config({
        // debug: true,
        openTagList,
        jsApiList,
        ...data,
      });
    })
    .catch((error) => {
      captureException(error, {
        tags: { scene: 'getSignature' },
      });
      throw error;
    });
}
