export default {
  component: () => import('./page.vue'),
  name: 'invioce',
  path: '/invioce',
  meta: {
    title: '电子票据',
    icon: 'coupon-o',
    color: 'text-cyan-500',
  },
};
