import { computed, watch } from 'vue';

import { KyJson } from '@eaphone/network';

import { sdkHook } from './index.js';
import { wxConfig } from './lib.js';

function hideMenuItems({ AppMessageShare, TimelineShare }) {
  wx.showAllNonBaseMenuItem();

  wx.hideMenuItems({
    menuList: [
      TimelineShare === false ? 'share:timeline' : undefined,
      AppMessageShare === false ? 'share:appMessage' : undefined,
      'favorite',
      'copyUrl',
      'openWithQQBrowser',
      'openWithSafari',
      'originPage',
      'readMode',
      'share:email',
      'share:facebook',
      'share:qq',
      'share:QZone',
      'share:weiboApp',
      'share:wechat',
    ]
      .filter(Boolean)
      .map((item) => `menuItem:${item}`),
  });
}

function getSignature(url, signal) {
  return KyJson.get('v1/weixin/mp/signature', {
    signal,
    auth: false,
    searchParams: { url },
  }).then(({ timestamp, nonceStr, signature, appId } = {}) => ({
    timestamp,
    nonceStr,
    signature,
    appId: appId || import.meta.env.APP_ID,
  }));
}

function runHook({
  AppMessageShare = false,
  TimelineShare = false,
  shareInfo,
}) {
  sdkHook()
    .then((sdk) => {
      hideMenuItems({ AppMessageShare, TimelineShare });

      if (AppMessageShare !== false) {
        sdk.updateAppMessageShareData(shareInfo);
      }

      if (TimelineShare !== false) {
        sdk.updateTimelineShareData(shareInfo);
      }
    })
    .catch(console.error);
}

function afterPageChange({
  AppMessageShare = true,
  TimelineShare = true,
  jsApiList = [],
  openTagList = [],
  shareInfo,
} = {}) {
  wxConfig(getSignature, {
    openTagList,
    jsApiList: [
      ...jsApiList,
      'showAllNonBaseMenuItem',
      'hideMenuItems',
      AppMessageShare === false ? false : 'updateAppMessageShareData',
      TimelineShare === false ? false : 'updateTimelineShareData',
    ].filter(Boolean),
  })
    .then(() => {
      runHook({ AppMessageShare, TimelineShare, shareInfo });
    })
    .catch(console.error);
}

export function usePageConfig(route) {
  const fullPath = computed(() => route.fullPath);

  watch(
    fullPath,
    (newPath, oldPath) => {
      if (
        !import.meta.env.WATCHING &&
        newPath &&
        newPath !== '/' &&
        newPath !== oldPath
      ) {
        setTimeout(() => {
          afterPageChange(route.meta?.wechat);
        }, 100);
      }
    },
    { immediate: true },
  );
}
