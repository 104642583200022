export default {
  component: () => import('./page.vue'),
  path: '/transportation',
  name: 'transportation',
  meta: {
    title: '医疗转运',
    icon: 'logistics',
    color: 'text-violet-500',
    auth: false,
  },
};
